<template>
  <div class="m-1">
    Loading...
  </div>
</template>

<script>
import appInitialDataMixin from '@/mixins/appInitialData'
import { fetchNewAuthToken } from '@/helpers/auth'

export default {
  mixins: [appInitialDataMixin],
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    const { code } = this.$route.query

    if (!code) {
      return this.$router.push('/')
    }
    return this.authorizeCode()
  },
  methods: {
    async authorizeCode() {
      try {
        const { code } = this.$route.query
        localStorage.setItem('code', code)

        await fetchNewAuthToken(code)
        await this.getAppInitialData()
        this.$router.replace('/')
      } catch (err) {
        // eslint-disable-next-line no-console
        // this.$swal({
        //   title: 'Authentication failed',
        //   icon: 'error',
        //   timer: 2000,
        //   allowEscapeKey: false,
        //   allowOutsideClick: false,
        //   showConfirmButton: false,
        // }).then(() => {

        // })
        this.$router.replace('/')
      }
    },
  },
}
</script>
